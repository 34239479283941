@font-face {
    font-family: 'Poppins-Bold';
    src: url(./assets/fonts/Poppins-Bold.ttf);
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url(./assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url(./assets/fonts/Poppins-Medium.ttf);
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url(./assets/fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: 'Poppins-BoldItalic';
    src: url(./assets/fonts/Poppins-BoldItalic.ttf);
}

@font-face {
    font-family: 'Poppins-Italic';
    src: url(./assets/fonts/Poppins-Italic.ttf);
}

@font-face {
    font-family: 'Poppins-Light';
    src: url(./assets/fonts/Poppins-Light.ttf);
}

@font-face {
    font-family: 'Poppins-LightItalic';
    src: url(./assets/fonts/Poppins-LightItalic.ttf);
}

@font-face {
    font-family: 'Tajawal-Light';
    src: url(./assets/fonts/Tajawal-Light.ttf);
}

@font-face {
    font-family: 'Tajawal-Medium';
    src: url(./assets/fonts/Tajawal-Medium.ttf);
}

@font-face {
    font-family: 'Tajawal-Regular';
    src: url(./assets/fonts/Tajawal-Regular.ttf);
}

@font-face {
    font-family: 'Tajawal-Bold';
    src: url(./assets/fonts/Tajawal-Bold.ttf);
}

@font-face {
    font-family: 'Tajawal-Black';
    src: url(./assets/fonts/Tajawal-Black.ttf);
}

@font-face {
    font-family: 'Tajawal-ExtraBold';
    src: url(./assets/fonts/Tajawal-ExtraBold.ttf);
}

@font-face {
    font-family: 'Tajawal-ExtraLight';
    src: url(./assets/fonts/Tajawal-ExtraLight.ttf);
}

body {
    background-color: #fff;
    /* background-image: url('https://firebasestorage.googleapis.com/v0/b/metadoc-app.appspot.com/o/WhatsApp%20Image%202022-05-09%20at%2012.27.36%20PM.jpeg?alt=media&token=5cb86d8e-0970-4780-ac77-ec5e1cf35244'); */
    /* background-repeat: no-repeat; */
    /* background-size: 800px  ; */

    background-position: right;
    /* height: 110vh; */
    margin: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

body::-webkit-scrollbar {
    width: 0;
    display: none;
}

html::-webkit-scrollbar {
    width: 0;
    display: none;
}

.leftSide {
    width: 30%;
}

.emptySpace {
    width: 120%;
    height: 120%;
    left: -10%;
    top: -10%;
    position: absolute;
    filter: blur(3rem);
    -webkit-filter: blur(3rem);
    /* opacity: 0.4; */
}

.modalView {
    display: none;
}

.grecaptcha-badge {
    visibility: hidden;
}

@media only screen and (max-width: 900px) {
    .leftSide {
        width: 100%;
    }

    .emptySpace {
        display: none;
    }
}

